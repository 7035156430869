$(document).on("turbo:load", function () {
  var $transactionForms = $(".new_transaction");

  if ($transactionForms.length) {
    $transactionForms.each(function () {
      let $transactionForm = $(this);
      var $paymentMethodInput = $transactionForm.find("#transaction_payment_method");
      var $creditCardIdInput = $transactionForm.find("#transaction_credit_card_id");
      var $combinedPaymentMethodInput = $transactionForm.find("#combined_payment_method");

      updateCreditCardIdFromCombinedPaymentMethod($creditCardIdInput, $paymentMethodInput, $combinedPaymentMethodInput);

      $combinedPaymentMethodInput.change(function (e) {
        updateCreditCardIdFromCombinedPaymentMethod(
          $creditCardIdInput,
          $paymentMethodInput,
          $combinedPaymentMethodInput,
        );
      });
    });
  }

  function updateCreditCardIdFromCombinedPaymentMethod(
    $creditCardIdInput,
    $paymentMethodInput,
    $combinedPaymentMethodInput,
  ) {
    var creditCardId = parseInt($combinedPaymentMethodInput.val());

    if (!isNaN(creditCardId)) {
      $creditCardIdInput.val(creditCardId);
      $paymentMethodInput.val("credit_card");
    } else {
      $creditCardIdInput.val("");
      $paymentMethodInput.val($combinedPaymentMethodInput.val());
    }
  }
});
