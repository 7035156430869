$(document).on("turbo:load", function () {
  const todaysDate = new Date();
  const date = setDateParam("date");
  const am_shift_start = date + " 7:00 AM";
  const am_shift_end = date + " 3:00 PM";
  const pm_shift_start = date + " 3:00 PM";
  const pm_shift_end = date + " 10:00 PM";
  const am_split_shift_start = date + " 7:00 AM";
  const am_split_shift_end = date + " 10:00 AM";

  $(function () {
    // Defaults to morning shift on load if the form is for new vehicle usage
    if (window.location.href.indexOf("new") > -1) {
      $("#am-shift-button").removeClass("btn-outline-info");
      $("#am-shift-button").addClass("btn-info");
      $("#vehicle_usage_start_time").val(am_shift_start);
      $("#vehicle_usage_end_time").val(am_shift_end);
    }
  });

  $("#am-shift-button").on("click", () => {
    $("#am-shift-button").removeClass("btn-outline-info");
    $("#am-shift-button").addClass("btn-info");
    $("#pm-shift-button").removeClass("btn-info");
    $("#pm-shift-button").addClass("btn-outline-info");
    $("#am-split-shift-button").removeClass("btn-info");
    $("#am-split-shift-button").addClass("btn-outline-info");
    $("#vehicle_usage_start_time").val(am_shift_start);
    $("#vehicle_usage_end_time").val(am_shift_end);
  });

  $("#pm-shift-button").on("click", () => {
    $("#pm-shift-button").removeClass("btn-outline-info");
    $("#pm-shift-button").addClass("btn-info");
    $("#am-shift-button").removeClass("btn-info");
    $("#am-shift-button").addClass("btn-outline-info");
    $("#am-split-shift-button").removeClass("btn-info");
    $("#am-split-shift-button").addClass("btn-outline-info");
    $("#vehicle_usage_start_time").val(pm_shift_start);
    $("#vehicle_usage_end_time").val(pm_shift_end);
  });

  $("#am-split-shift-button").on("click", () => {
    $("#am-split-shift-button").removeClass("btn-outline-info");
    $("#am-split-shift-button").addClass("btn-info");
    $("#am-shift-button").removeClass("btn-info");
    $("#am-shift-button").addClass("btn-outline-info");
    $("#pm-shift-button").removeClass("btn-info");
    $("#pm-shift-button").addClass("btn-outline-info");
    $("#vehicle_usage_start_time").val(am_split_shift_start);
    $("#vehicle_usage_end_time").val(am_split_shift_end);
  });

  function setDateParam(param) {
    if (urlParam(param) === undefined) {
      return formatDate(todaysDate);
    } else {
      return urlParam(param);
    }
  }

  function urlParam(name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results !== null) {
      return results[1] || 0;
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
});
