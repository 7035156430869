$(document).on("turbo:load", function () {
  function setupDatepickers() {
    // initialize any date or date/time pickers on page load
    $(".flat_date_picker").flatpickr({
      // dateFormat: "F J Y, h:i:S K",
    });

    $(".flat_time_picker").flatpickr({
      enableTime: true,
      noCalendar: false, // TODO: change back to true once we solve the weird month bug...
      dateFormat: "Y-m-d h:i K",
      // defaultDate: urlParam('date') || new Date()
    });

    $(".flat_date_time_picker").flatpickr({
      enableTime: true,
      // dateFormat: "F J Y, h:i:S K",
      static: true,
    });

    $(".schedule_flat_date_time_picker").flatpickr({
      enableTime: true,
      dateFormat: "Y-m-d h:i K",
      minuteIncrement: 15,
      static: true,
    });

    $("#schedule_calendar_date_picker").flatpickr({
      dateFormat: "Y-m-d",
      defaultDate: urlParam("date") || new Date(),
      onChange: function (selectedDates, dateStr) {
        let schedule_url = "/schedule";
        schedule_url = `${schedule_url}?date=${dateStr}`;
        if (urlParam("branch_id")) {
          schedule_url = `${schedule_url}&branch_id=${urlParam("branch_id")}`;
        }
        if (urlParam("appointment_id")) {
          schedule_url = `${schedule_url}&appointment_id=${urlParam("appointment_id")}`;
        }
        if (urlParam("reservation_id")) {
          schedule_url = `${schedule_url}&reservation_id=${urlParam("reservation_id")}`;
        }
        if (urlParam("new_switchout")) {
          schedule_url = `${schedule_url}&new_switchout=${urlParam("new_switchout")}`;
        }
        if (urlParam("new_delivery")) {
          schedule_url = `${schedule_url}&new_delivery=${urlParam("new_delivery")}`;
        }
        if (urlParam("new_pickup")) {
          schedule_url = `${schedule_url}&new_pickup=${urlParam("new_pickup")}`;
        }
        Turbo.visit(schedule_url);
      },
      disableMobile: "true",
    });
  }

  const urlParam = function (name) {
    let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results !== null) {
      return results[1] || 0;
    }
  };
  // set timeout to also handle anything rendered by React after page load
  // FIXME: is there a better way to handle this? bandaid for now...
  setTimeout(function () {
    setupDatepickers();
  });
});
