<script lang="ts" context="module">
  import type { InventoryItemType } from "models/InventoryItem";
  import type { RentalUnit } from "models/RentalUnit";

  const TYPE_LABELS: Record<InventoryItemType, string> = {
    Ski: "Skis",
    SkiBoot: "Ski Boots",
    Snowboard: "Snowboard",
    SnowboardBoot: "Snowboard Boots",
    XcSki: "XC Skis",
    XcBoot: "XC Boots",
    Helmet: "Helmet",
    Bike: "Bike",
    Tube: "Tube",
    Paddleboard: "Paddleboard",
    Jacket: "Jacket",
    Pant: "Pant",
    Pole: "Poles",
  };
</script>

<script lang="ts">
  export let rentalUnit: RentalUnit;
  export let onRemove: (rentalUnit: RentalUnit) => void;

  $: ({ inventory_item, barcode_sku, rental_sku } = rentalUnit);
  $: ({ description } = inventory_item);
  $: typeLabel = TYPE_LABELS[inventory_item.type];
</script>

<div class="card shadow mt-2">
  <div class="card-body">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div>
        <h5 class="h6 card-subtitle mb-1 text-muted text-uppercase">{typeLabel}</h5>
        <h4 class="h5 card-title mb-0">{description}</h4>
        <div class="mt-1 d-flex align-items-center">
          {#if barcode_sku && barcode_sku.length > 0}
            <div>
              <i class="fas fa-barcode me-2" />
              <span>{barcode_sku}</span>
            </div>
          {/if}

          {#if rental_sku && rental_sku.length > 0}
            <div class="ms-4">
              <i class="fas fa-tag me-2" />
              <span>{rental_sku}</span>
            </div>
          {/if}
        </div>
      </div>

      <div>
        <button type="button" class="btn btn-outline-secondary" on:click={() => onRemove(rentalUnit)}>
          <i class="fas fa-times me-2" />
          <span>Remove</span>
        </button>
      </div>
    </div>
  </div>
</div>
