$(document).on("turbo:load", function () {
  setupDeliveryForm();
  setupPickupForm();
  setupServiceRequestForm();

  function setupDeliveryForm() {
    const $deliveryForm = $("#reservation-delivery-form");
    $deliveryForm.formPrefix = "delivery_form";
    if ($deliveryForm.length) {
      setupVehicleUsageButtons($deliveryForm);
    }
  }

  function setupPickupForm() {
    const $pickupForm = $("#reservation-pickup-form");
    $pickupForm.formPrefix = "pickup_form";
    if ($pickupForm.length) {
      setupVehicleUsageButtons($pickupForm);
    }
  }

  function setupServiceRequestForm() {
    const $serviceRequestForm = $("#reservation-service-request-form");
    $serviceRequestForm.formPrefix = "service_request_form";
    if ($serviceRequestForm.length) {
      setupVehicleUsageButtons($serviceRequestForm);
      setupRentalIssueButtons($serviceRequestForm);
    }
  }

  //=====================================================================
  // Vehicle add/remove
  //=====================================================================

  function setupVehicleUsageButtons($form) {
    $form.find(".add_vehicle_button").click(function (e) {
      e.preventDefault();
      addVehicle($form);
    });

    $form.find(".remove_vehicle_button").click(function (e) {
      e.preventDefault();
      removeVehicle($form, $(this).parents(".vehicle"));
    });

    hideVehicleRemoveButtonIfOnlyOne($form);
  }

  function hideVehicleRemoveButtonIfOnlyOne($form) {
    const $removeVehicleButtons = $form.find(".remove_vehicle_button");
    if ($form.find(".vehicle").length > 1) {
      $removeVehicleButtons.show();
    } else {
      $removeVehicleButtons.hide();
    }
  }

  function addVehicle($form) {
    // keep a count of added vehicles, for sake of indexed IDs on elements
    if ($form._addedVehiclesCount === undefined) {
      $form._addedVehiclesCount = 1;
    } else {
      $form._addedVehiclesCount = $form._addedVehiclesCount + 1;
    }

    // clone the last rental issue
    const $vehiclesContainer = $form.find(".vehicles")[0];
    $form.find(".vehicle").last().clone().appendTo($vehiclesContainer);
    const $vehicles = $form.find(".vehicle");
    const $newVehicle = $vehicles.last();

    // give the fieldset container a unique ID for scoping sake
    $newVehicle.attr("id", `vehicle_new_${$form._addedVehiclesCount}`);

    // clear the inputs and give them correctly indexed names and IDs
    const newVehicleIndex = $vehicles.length - 1;
    const newVehicleInputId = `${$form.formPrefix}_vehicle_ids_${newVehicleIndex}`;
    // const newVehicleInputName = `${$form.formPrefix}[vehicle_ids][${newVehicleIndex}]`;
    const $newVehicleInput = $newVehicle.find(".vehicle_id_input");
    $newVehicle.find("label").html(`Vehicle #${newVehicleIndex + 1}`);
    $newVehicleInput.attr("id", newVehicleInputId);
    // $newVehicleInput.attr('name', newVehicleInputName);

    $newVehicle.find(".remove_vehicle_button").click(function (e) {
      e.preventDefault();
      removeVehicle($form, $(this).parents(".vehicle"));
    });
    hideVehicleRemoveButtonIfOnlyOne($form);
  }

  function removeVehicle($form, $vehicle) {
    $vehicle.remove();
    hideVehicleRemoveButtonIfOnlyOne($form);
  }

  //=====================================================================
  // Rental Issue add/remove (service requests ONLY)
  //=====================================================================

  function setupRentalIssueButtons($serviceRequestForm) {
    $serviceRequestForm.find("#add-guest").click($serviceRequestForm, addRentalIssue);
    $serviceRequestForm.find(".removable .remove-guest").click($serviceRequestForm, removeRentalIssue);
    hideRentalIssueRemoveButtonIfOnlyOne($serviceRequestForm);
  }

  function hideRentalIssueRemoveButtonIfOnlyOne($serviceRequestForm) {
    const $removeButtons = $serviceRequestForm.find(".remove-guest");
    if ($serviceRequestForm.find(".rental-issue").length > 1) {
      $removeButtons.show();
    } else {
      $removeButtons.hide();
    }
  }

  function addRentalIssue(e) {
    e.preventDefault();

    const $serviceRequestForm = e.data;

    // keep a count of added rental issues, for sake of indexed IDs on elements
    if ($serviceRequestForm._addedRentalIssuesCount === undefined) {
      $serviceRequestForm._addedRentalIssuesCount = 1;
    } else {
      $serviceRequestForm._addedRentalIssuesCount = $serviceRequestForm._addedRentalIssuesCount + 1;
    }

    // clone the last rental issue
    const $rentalIssuesContainer = $serviceRequestForm.find(".rental-issues")[0];
    $serviceRequestForm.find(".rental-issue").last().clone().appendTo($rentalIssuesContainer);
    const $rentalIssues = $serviceRequestForm.find(".rental-issue");
    const $newRentalIssue = $rentalIssues.last();

    // give the fieldset container a unique ID for scoping sake
    $newRentalIssue.attr("id", `rental_issue_new_${$serviceRequestForm._addedRentalIssuesCount}`);

    // clear the id and description inputs
    $newRentalIssue.find(".id_input").val("");
    $newRentalIssue.find(".description_input").val("");

    // clear the inputs and give them correctly indexed names and IDs
    const newRentalIssueIndex = $rentalIssues.length - 1;
    const rentalIssuesInputIdPrefix = "service_request_form_rental_issues_attributes";
    const rentalIssuesInputNamePrefix = "service_request_form[rental_issues_attributes]";
    const indexedInputIdPrefix = `${rentalIssuesInputIdPrefix}_${newRentalIssueIndex}`;
    const indexedInputNamePrefix = `${rentalIssuesInputNamePrefix}[${newRentalIssueIndex}]`;
    const attributes = ["id", "rental_id", "description"];
    attributes.forEach(function (attr) {
      $newRentalIssue.find(`.${attr}_input`).attr("id", `${indexedInputIdPrefix}_${attr}`);
      $newRentalIssue.find(`.${attr}_input`).attr("name", `${indexedInputNamePrefix}[${attr}]`);
      $newRentalIssue.find(`.${attr}_label`).attr("for", `${indexedInputIdPrefix}_${attr}`);
    });

    $newRentalIssue.find(".remove-guest").click($serviceRequestForm, removeRentalIssue);
    hideRentalIssueRemoveButtonIfOnlyOne($serviceRequestForm);
  }

  function removeRentalIssue(e) {
    e.preventDefault();

    const $rentalIssue = $(this).parents(".rental-issue")[0];
    $rentalIssue.remove();

    const $serviceRequestForm = e.data;
    hideRentalIssueRemoveButtonIfOnlyOne($serviceRequestForm);
  }
});
