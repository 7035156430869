<script lang="ts" context="module">
  import type { Brand } from "models/Brand";
</script>

<script lang="ts">
  import RemoteSelect from "./RemoteSelect.svelte";

  export let inputId: string;
  export let inputName: string;
  export let inputLabel: string | undefined = undefined;
  export let inputPlaceholder = "Find by name...";

  export let value: number | null = null;
  export let selected: Brand | null = null;
  export let onSelected: (selected: Brand) => void = () => {};
</script>

<RemoteSelect
  {inputId}
  {inputName}
  {inputLabel}
  {inputPlaceholder}
  bind:value
  bind:selected
  {onSelected}
  searchUrl="/api/v1/brands"
  searchParamKey="q"
  searchResultsNamespace="brands"
  itemIdProperty="id"
  itemLabelProperty="name"
/>
