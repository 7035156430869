$(document).on("turbo:load", function () {
  const $newInventoryAdditionForm = $("#new_inventory_addition");

  if ($newInventoryAdditionForm.length) {
    $newInventoryAdditionForm.find("#add-inventory-item").click(addInventoryItem);
    $(".remove-inventory-item").click(removeInventoryItem);
  }

  function addInventoryItem(e) {
    e.preventDefault();

    // get indexed id from last input, so we can give the new one the next index
    const $previouslyLastInventoryItem = $(".inventory-item-fields").last();
    const i = parseInt($previouslyLastInventoryItem.attr("id").slice(-1));

    $(".inventory-item-fields").last().clone().appendTo(".inventory-items");

    // reset inputs on added form line
    const $newInventoryItem = $(".inventory-item-fields").last();
    $newInventoryItem.find("input:not(:hidden)").val("");

    // show remove buttons if we're back over 1 inventory item -- they might've been hidden by remove
    if ($(".inventory-item-fields").length > 1) {
      $(".remove-inventory-item").show();
    }

    // make sure newly added row has remove button click handler
    $newInventoryItem.find(".remove-inventory-item").click(removeInventoryItem);

    // give it an indexed ID
    $newInventoryItem.attr("id", `inventory-item-fields-${i + 1}`);

    // focus first input in newly added form row
    $newInventoryItem.find(":input").first().focus();
  }

  function removeInventoryItem(e) {
    e.preventDefault();

    if ($(".inventory-item-fields").length > 1) {
      $(this).parents(".inventory-item-fields").remove();
    }

    // if only one left afterward, hide the remove button, since it won't work
    if ($(".inventory-item-fields").length <= 1) {
      $(".remove-inventory-item").hide();
    }
  }
});
