<script lang="ts" context="module">
  import type { Location } from "models/Location";

  interface Option {
    value: number | string;
    label: string;
    new?: boolean;
  }

  function labelFor(location: Location): string {
    return [location.name, location.street].filter((s) => s && s.length > 0).join(" - ");
  }

  function optionFor(location: Location): Option {
    return { value: location.id, label: labelFor(location) };
  }

  function locationFromOption(option: Option): Location {
    const id = typeof option.value === "string" ? Number.parseInt(option.value) : option.value;
    return { id, name: option.label };
  }
</script>

<script lang="ts">
  import LocationSelect from "../LocationSelect.svelte";

  export let formPrefix: string;
  export let locationSameAsReservation: boolean;
  export let reservationLodging: Location | null = null;
  export let reservationUnitNumber: string | null = null;
  export let selectedLocation: Location | null = null;
  export let unitNumber: string | null = null;
  export let branchId: number;

  console.log(selectedLocation);

  let selectedLocationOption: Option | null = selectedLocation ? optionFor(selectedLocation) : null;

  $: selectedLocation = selectedLocationOption ? locationFromOption(selectedLocationOption) : null;

  $: if (locationSameAsReservation) {
    if (reservationLodging) {
      selectedLocationOption = optionFor(reservationLodging);
    }
    unitNumber = reservationUnitNumber;
  }
</script>

<div class="appointment-location-fields mb-3">
  <div class="form-group mb-3 boolean delivery_form_location_same_as_reservation">
    {#if locationSameAsReservation === false}
      <input type="hidden" name="{formPrefix}[location_same_as_reservation]" value="0" />
    {/if}

    <input
      class="custom-control-input boolean"
      type="checkbox"
      value={locationSameAsReservation ? "1" : "0"}
      bind:checked={locationSameAsReservation}
      name="{formPrefix}[location_same_as_reservation]"
      id="{formPrefix}_location_same_as_reservation"
    />

    <label class="custom-control-label boolean" for="{formPrefix}_location_same_as_reservation"
      >Location same as reservation?</label
    >
  </div>

  <LocationSelect
    inputId="{formPrefix}_location_id"
    inputName="{formPrefix}[location_id]"
    label="Location"
    value={selectedLocation?.id}
    selected={selectedLocation}
    bind:selectedOption={selectedLocationOption}
    {branchId}
    disabled={locationSameAsReservation}
  />

  <div class="form-group">
    <label for="{formPrefix}_unit_number">Unit Number</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="{formPrefix}_unit_number"
      name="{formPrefix}[unit_number]"
      bind:value={unitNumber}
      disabled={locationSameAsReservation}
    />
  </div>
</div>
