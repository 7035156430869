import { Controller } from "stimulus";
import { Collapse } from "bootstrap";

export default class extends Controller {
  static targets = ["toggleButton", "collapseContainer"];

  collapseShown = false;
  bsCollapse = null;

  connect() {
    if (this.hasCollapseContainerTarget) {
      this.bsCollapse = new Collapse(this.collapseContainerTarget, { toggle: false });
    }
  }

  toggleCollapse() {
    if (this.collapseShown) {
      this.hideCollapse();
    } else {
      this.showCollapse();
    }
  }

  showCollapse() {
    this.collapseShown = true;
    this.bsCollapse.show();
    this.toggleButtonText.textContent = this.toggleButtonText.textContent.replace("Show", "Hide");
    this.toggleButtonIcon.classList.remove("fa-caret-down");
    this.toggleButtonIcon.classList.add("fa-caret-up");
  }

  hideCollapse() {
    this.collapseShown = false;
    this.bsCollapse.hide();
    this.toggleButtonText.textContent = this.toggleButtonText.textContent.replace("Hide", "Show");
    this.toggleButtonIcon.classList.remove("fa-caret-up");
    this.toggleButtonIcon.classList.add("fa-caret-down");
  }

  get toggleButtonText() {
    return this.toggleButtonTarget.querySelector("span");
  }

  get toggleButtonIcon() {
    return this.toggleButtonTarget.querySelector("svg");
  }
}
