<script lang="ts" context="module">
  interface Branch {
    id: number;
    name: string;
  }

  interface RentalPackage {
    id: number;
    name: string;
  }

  interface Rental {
    id: number;
    name: string;
    start_time: string; // ISO datetime string
    end_time: string; // ISO datetime string
    rental_unit_id: number;
  }

  interface RentalUnit {
    id: number;
    name: string;
    brand: string;
    model: string;
    barcode_sku: string;
    rental_sku: string;
  }
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import { Calendar, type CalendarOptions } from "@fullcalendar/core";
  import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
  import interactionPlugin from "@fullcalendar/interaction";
  import flatpickr from "flatpickr";
  import axios from "axios";

  export let branches: Branch[];
  export let rentalPackages: RentalPackage[];

  let loading = true;
  let startDate: Date = new Date();
  let endDate: Date = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
  let branchId: number = branches[0].id;
  let selectedRentalPackageId: number | null = null;
  let selectedSize: string | null = null;
  let rentals: Rental[] = [];
  let rentalUnits: RentalUnit[] = [];

  let dateRangeInput: HTMLInputElement & { _flatpickr?: any };
  let calendar: Calendar;
  let calendarDiv: HTMLDivElement;
  let calendarOptions: CalendarOptions;

  onMount(() => fetchTimelineData());

  $: if (startDate || endDate || branchId || selectedRentalPackageId || selectedSize) fetchTimelineData();

  $: if (dateRangeInput && !dateRangeInput._flatpickr) {
    flatpickr(dateRangeInput, {
      mode: "range",
      dateFormat: "Y-m-d",
      defaultDate: [startDate, endDate],
      onChange: (selectedDates) => {
        if (selectedDates.length !== 2) return;
        const [newStartDate, newEndDate] = selectedDates;
        startDate = newStartDate;
        endDate = newEndDate;
      },
    });
  }

  async function fetchTimelineData() {
    loading = true;

    try {
      const url = "/api/v1/bike_timeline";
      const params = {
        branch_id: branchId,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        rental_package_ids: [selectedRentalPackageId].filter((id) => id !== null),
        sizes: [selectedSize].filter((size) => size !== null),
      };

      const response = await axios.get(url, { params });
      if (response.status === 200) {
        const bikeTimeline = response.data.bike_timeline;
        rentals = bikeTimeline.rentals;
        rentalUnits = bikeTimeline.rental_units;
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading = false;
    }
  }

  function resetSearch() {
    startDate = new Date();
    endDate = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
    branchId = branches[0].id;
    selectedRentalPackageId = null;
    selectedSize = null;
  }

  $: calendarOptions = {
    plugins: [resourceTimelinePlugin, interactionPlugin],
    initialView: "resourceTimelineCustom",
    schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
    resources: rentalUnits.map((ru) => {
      let title = [ru.brand, ru.model].filter((x) => x).join(" ");
      if (ru.barcode_sku) {
        title += ` [${ru.barcode_sku}]`;
      } else if (ru.rental_sku) {
        title += ` [${ru.rental_sku}]`;
      }

      return { id: ru.id.toString(), title };
    }),
    events: rentals.map((r) => {
      return {
        id: r.id.toString(),
        start: r.start_time,
        end: r.end_time,
        resourceId: r.rental_unit_id.toString(),
        title: r.name,
      };
    }),
    editable: false,
    eventTextColor: "#FFF",
    headerToolbar: false,
    height: 650,
    resourceAreaWidth: "20%",
    resourceLabelText: "Rental Units",
    slotLabelFormat: { weekday: "short", month: "short", day: "numeric" },
    visibleRange: { start: startDate, end: endDate }, // new Date(endDate.getTime() + 1000 * 60 * 60 * 24) }}
    views: {
      resourceTimelineCustom: {
        type: "resourceTimeline",
        slotDuration: "24:00:00",
      },
    },
  };

  $: if (calendarDiv) {
    console.log("Rendering");
    calendar = new Calendar(calendarDiv, calendarOptions);
    calendar.render();
  }

  $: console.log("Updated calendarOptions", calendarOptions);
</script>

<div class="bike-timeline-container">
  <header class="d-flex flex-wrap align-items-center justify-content-between">
    <h2 class="mt-2">Bike Timeline</h2>

    <div>
      {#if loading}
        <p class="text-primary mb-0 h6">
          <i class="fas fa-spinner fa-spin" />
          <span class="ms-1">Searching...</span>
        </p>
      {/if}
    </div>
  </header>

  <div id="bike-search-form">
    <div class="input-group mt-3">
      <div class="input-group-text">
        <i class="fas fa-search" />
      </div>

      <input bind:this={dateRangeInput} type="text" name="date_range" class="form-control" />

      <select name="branch_id" id="branch_id" class="form-control" bind:value={branchId}>
        {#each branches as branch (branch.id)}
          <option value={branch.id}>{branch.name}</option>
        {/each}
      </select>

      <select bind:value={selectedRentalPackageId} name="rental_package_id" id="rental_package_id" class="form-control">
        <option value={null}>Filter by package...</option>
        {#each rentalPackages as rp (rp.id)}
          <option value={rp.id}>{rp.name}</option>
        {/each}
      </select>

      <select bind:value={selectedSize} name="size" id="size" class="form-control">
        <option value={null}>Filter by size...</option>
        <option value="20">Junior 20&quot;</option>
        <option value="24">Junior 24&quot;</option>
        <option value="XS">X-Small</option>
        <option value="S">Small</option>
        <option value="M">Medium</option>
        <option value="L">Large</option>
        <option value="XL">X-Large</option>
      </select>

      <button type="button" class="btn btn-secondary" on:click={resetSearch}> Reset </button>
    </div>
  </div>

  <section class="mt-4">
    <div bind:this={calendarDiv} />
  </section>
</div>
