import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["acceptCheckbox", "continueLinkWrapper", "continueButton"];

  connect() {
    this.acceptBeforeContinue();
  }

  get linksToDisable() {
    if (this.hasContinueLinkWrapperTarget) {
      return this.continueLinkWrapperTarget.querySelectorAll("a");
    } else {
      return [];
    }
  }

  get buttonsToDisable() {
    return this.continueButtonTargets;
  }

  isChecked(checkbox) {
    if (checkbox.checked == true) {
      return true;
    }
  }

  acceptBeforeContinue() {
    if (this.acceptCheckboxTargets.every(this.isChecked) == true) {
      this.linksToDisable.forEach((link) => {
        link.classList.remove("disabled");
      });
      this.buttonsToDisable.forEach((button) => {
        button.disabled = false;
      });
    } else {
      this.linksToDisable.forEach((link) => {
        link.classList.add("disabled");
      });
      this.buttonsToDisable.forEach((button) => {
        button.disabled = true;
      });
    }
  }
}
