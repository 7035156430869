import { Controller } from "stimulus";
// import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper-https";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export default class extends Controller {
  static targets = ["printButton"];

  async connect() {
    this.browserPrint = new ZebraBrowserPrintWrapper();
    const defaultPrinter = await this.browserPrint.getDefaultPrinter();
    this.browserPrint.setPrinter(defaultPrinter);
    const printerStatus = await this.browserPrint.checkPrinterStatus();
    console.log(printerStatus);
  }

  async print() {
    console.log("Printing");
    const defaultPrinter = await this.browserPrint.getDefaultPrinter();
    this.browserPrint.setPrinter(defaultPrinter);
    const printerStatus = await this.browserPrint.checkPrinterStatus();

    if (printerStatus.isReadyToPrint) {
      this.browserPrint.print(this.data.get("zpl"));
      console.log("Print succes");
    } else {
      console.log("Error/s", printerStatus.errors);
    }
  }
}
