import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'primaryBadge', 'primaryFlagInput' ]

    connect() {
        console.log(this.primaryBadgeTargets)
    }

    setAsPrimary(event) {
        event.preventDefault();

        // hide all primary badges
        this.primaryBadgeTargets.forEach((el) => {
            el.classList.add('d-none')
            el.classList.remove('d-block')
        })

        // set 'set_as_primary' to false for all
        this.primaryFlagInputTargets.forEach((el) => {
            el.value = false
        })

        // un-hide the badge for the new primary customer
        const newPrimaryReservationCustomer = event.currentTarget.closest('.reservation-customer')
        const newPrimaryBadge = newPrimaryReservationCustomer.querySelector('.primary-badge')
        newPrimaryBadge.classList.add('d-block')
        newPrimaryBadge.classList.remove('d-none')

        // set 'set_as_primary' to true for new primary customer
        const newPrimaryFlagInput = newPrimaryReservationCustomer.querySelector('.reservation_customer_set_as_primary')
        newPrimaryFlagInput.value = true
    }
}
