<script lang="ts" context="module">
  // const BLUE = "#0000b9";
  // const RED = "#b20000";
  // const PURPLE = "#7a006f";
  // const GREEN = "#007200";
  const YELLOW = "#f9d55e";
  const GREY = "#bdbdbd";

  interface AppointmentUpdateParams {
    id: number;
    start_time: string; // ISO date time string
    end_time: string; // ISO date time string
    vehicle_usage_ids?: number[];
  }
</script>

<script lang="ts">
  import { Calendar, type CalendarOptions, type EventApi } from "@fullcalendar/core";
  import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  import { Turbo } from "@hotwired/turbo-rails";
  import axios from "axios";

  export let date: string;
  export let shift: "am" | "pm";
  export let branchId: number;
  export let reservationId: number;
  export let appointmentId: number;

  let calendar: Calendar;
  let calendarDiv: HTMLDivElement;
  let calendarOptions: CalendarOptions;

  async function updateAppointmentFromEvent(updatedEvent: EventApi, revert: () => void) {
    if (!confirm("Are you sure you want to save this change?")) {
      revert();
      return;
    }

    if (updatedEvent.start === null || updatedEvent.end === null) {
      console.error("Event start or end is null");
      revert();
      return;
    }

    try {
      const appointment: AppointmentUpdateParams = {
        id: Number.parseInt(updatedEvent.id),
        start_time: updatedEvent.start.toISOString(),
        end_time: updatedEvent.end.toISOString(),
        vehicle_usage_ids: updatedEvent.getResources().map((r) => Number.parseInt(r.id)),
      };

      const { update_url } = updatedEvent.extendedProps;
      const response = await axios.patch(update_url, { appointment });
      if (response.status !== 200) {
        console.error(response.data);
        revert();
        alert("Oops, there was an error saving the appointment update. Please try again");
      }
    } catch (err) {
      console.error(err);
      revert();
      alert("Oops, there was an error saving the appointment update. Please try again");
    }
  }

  $: calendarOptions = {
    plugins: [resourceTimeGridPlugin, interactionPlugin],
    initialView: "resourceTimeGridDay",
    schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
    snapDuration: "00:15:00",
    resourceOrder: "vehicle_id",
    headerToolbar: false,
    footerToolbar: false,
    height: "auto",
    initialDate: date,
    nowIndicator: true,
    refetchResourcesOnNavigate: true, // Enables sending start and end params for resources
    displayEventEnd: false,
    editable: true,
    eventDurationEditable: true,
    allDaySlot: false,
    events: `/api/v1/calendar_events?date=${date}&shift=${shift}&branch_id=${branchId}`,
    resources: `/api/v1/vehicle_usages?date=${date}&shift=${shift}&branch_id=${branchId}`,
    slotMinTime: shift === "am" ? "07:00:00" : "15:00:00",
    slotMaxTime: shift === "am" ? "15:00:00" : "22:00:00",
    // businessHours: {
    //   daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    //   startTime: "07:00",
    //   endTime: "22:00",
    // },
    eventDrop: ({ event, revert }) => updateAppointmentFromEvent(event, revert),
    eventResize: ({ event, revert }) => updateAppointmentFromEvent(event, revert),
    eventClick: ({ event }) => {
      let queryParams = [];
      queryParams.push(`appointment_id=${event.id}`);
      queryParams.push(`date=${date}`);
      const url = `/schedule?${queryParams.join("&")}`;
      Turbo.visit(url);
    },
    resourceLabelDidMount: ({ resource, date, el }) => {
      if (date === undefined) return;
      const link = document.createElement("a");
      link.id = `edit_vehicle_usage_${resource.id}`;
      link.classList.add("ms-3", "link", "link-primary", "text-decoration-none");
      link.href = `/vehicle_usages/${resource.id}/edit?date=${date.toISOString().substring(0, 10)}`;
      link.innerText = "Edit";
      el.firstChild?.appendChild(link);
    },
    eventDidMount: ({ event, el }) => {
      const { status, unit_number, appointment_notes } = event.extendedProps;

      if (Number.parseInt(event.id) === appointmentId) {
        event.setProp("backgroundColor", YELLOW);
        el.style.borderWidth = "2px";
      }

      if (status === "confirmed") {
        el.querySelector(".fc-time")?.prepend("<i class='fas fa-check'></i>");
      } else if (status == "completed") {
        el.querySelector(".fc-time")?.prepend("<i class='fas fa-check-double me-1'></i>");
        el.style.backgroundColor = GREY;
      }

      if (unit_number) {
        el.querySelector(".fc-title")?.append("<i class='fas fa-key' style='margin-left:0.5rem;'></i>");
      }
      if (appointment_notes && appointment_notes.length > 0) {
        el.querySelector(".fc-title")?.append("<i class='fas fa-sticky-note' style='margin-left:0.5rem;'></i>");
      }
    },
  };

  $: if (calendarDiv) {
    calendar = new Calendar(calendarDiv, calendarOptions);
    calendar.render();
  }
</script>

<div bind:this={calendarDiv} />
