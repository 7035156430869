import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["selectAll", "deselectAll", "selectable"]

    selectAll() {
       this.selectableTargets.forEach(target => {
           target.checked = true
       })
    }

    deselectAll() {
        this.selectableTargets.forEach(target => {
            target.checked = false
        })
    }
}