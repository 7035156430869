<script lang="ts" context="module">
  import axios, { type AxiosError } from "axios";
  import type { InventoryItemType } from "models/InventoryItem";
  import type { RentalUnit } from "models/RentalUnit";
  import type { Rental } from "models/Rental";
  import type { EquipmentAssignment } from "models/EquipmentAssignment";
  import type { BikeAssignment } from "models/BikeAssignment";
  import type { TubeAssignment } from "models/TubeAssignment";

  const RENTAL_UNITS_URL = "/api/v1/rental_units";

  const fetchRentalUnitsByBarcode = async (barcode: string, areaId: number): Promise<RentalUnit[]> => {
    let results: RentalUnit[] = [];

    try {
      const params = { q: barcode, area_id: areaId };
      const response = await axios.get(RENTAL_UNITS_URL, { params });
      if (response.status === 200) {
        results = response.data.rental_units;
      } else {
        console.error(response.data);
      }
    } catch (err) {
      console.error(err);
    }

    return results;
  };

  interface ValidationResult {
    isValid: boolean;
    errors: string[];
  }

  const validateRentalUnitsForEquipmentAssignment = (rentalUnits: RentalUnit[]): ValidationResult => {
    let result: ValidationResult = { isValid: true, errors: [] };

    const countsByType: Record<InventoryItemType, number> = {
      Ski: 0,
      SkiBoot: 0,
      Snowboard: 0,
      SnowboardBoot: 0,
      Helmet: 0,
      XcSki: 0,
      XcBoot: 0,
      Bike: 0,
      Tube: 0,
      Paddleboard: 0,
      Jacket: 0,
      Pant: 0,
      Pole: 0,
    };

    rentalUnits.forEach((ru) => countsByType[ru.inventory_item.type]++);

    // must have atleast one rental unit to submit
    if (Object.values(countsByType).every((c) => c === 0)) {
      result.errors.push("no rental units are present");
      result.isValid = false;
    }

    // invalid if more than one item of any type is present
    if (Object.values(countsByType).some((c) => c > 1)) {
      result.errors.push("more than one of a type is selected");
      result.isValid = false;
    }

    // cannot select both a ski and a snowboard
    if (countsByType["Ski"] > 0 && countsByType["Snowboard"] > 0) {
      result.errors.push("both skis and board are present");
      result.isValid = false;
    }

    // cannot select both a ski boot and a snowboard boot
    if (countsByType["SkiBoot"] > 0 && countsByType["SnowboardBoot"] > 0) {
      result.errors.push("both boot types are present");
      result.isValid = false;
    }

    // can only select one bike
    if (countsByType["Bike"] > 1) {
      result.errors.push("more than one bike is selected");
      result.isValid = false;
    }

    // can only select one tube
    if (countsByType["Tube"] > 1) {
      result.errors.push("more than one tube is selected");
      result.isValid = false;
    }

    // can only select one paddleboard
    if (countsByType["Paddleboard"] > 1) {
      result.errors.push("more than one paddleboard is selected");
      result.isValid = false;
    }

    // can only select ski + ski boot OR snowboard + snowboardboot
    if (countsByType["Ski"] > 0 && countsByType["SnowboardBoot"] > 0) {
      result.errors.push("both skis and board are present");
      result.isValid = false;
    }
    if (countsByType["Snowboard"] > 0 && countsByType["SkiBoot"] > 0) {
      result.errors.push("both skis and board are present");
      result.isValid = false;
    }

    return result;
  };
</script>

<script lang="ts">
  import BarcodeScanner from "~/components/svelte/BarcodeScanner.svelte";
  import RentalUnitSelect from "~/components/svelte/RentalUnitSelect.svelte";
  import RentalUnitCard from "~/components/svelte/RentalUnitCard.svelte";
  import type { PaddleboardAssignment } from "models/PaddleboardAssignment";

  export let rental: Rental;
  export let areaId: number;
  export let facingMode: MediaTrackConstraints["facingMode"];
  export let itemTypes: InventoryItemType[];
  export let env: "production" | "development" | "test";

  let rentalUnits: RentalUnit[] = [];
  let validation: ValidationResult = { isValid: false, errors: [] };
  let fetching: boolean = false;
  let submitting: boolean = false;
  let submitted: boolean = false;
  let errors: string[] = [];
  let unknownBarcodes: string[] = [];

  $: validation = validateRentalUnitsForEquipmentAssignment(rentalUnits);

  const onDetected = async (code: string) => {
    console.log("Barcode detected:", code);
    fetching = true;
    const matchingRentalUnits = await fetchRentalUnitsByBarcode(code, areaId);
    if (matchingRentalUnits.length > 0) {
      rentalUnits = [...rentalUnits, ...matchingRentalUnits];
    } else {
      unknownBarcodes = [...unknownBarcodes, code];
    }
    fetching = false;
  };

  const onSelectedViaAutosuggest = (rentalUnit: RentalUnit) => {
    console.log("Rental unit selected:", rentalUnit);
    rentalUnits = [...rentalUnits, rentalUnit];
  };

  const removeRentalUnit = (rentalUnit: RentalUnit) => {
    const indexToRemove = rentalUnits.findIndex((ru) => ru.id === rentalUnit.id);
    const newRentalUnits = [...rentalUnits];
    newRentalUnits.splice(indexToRemove, 1);
    rentalUnits = newRentalUnits;
  };

  const onSubmit = async () => {
    if (!validation.isValid) return;
    submitting = true;

    try {
      const skiRentalUnit = rentalUnits.find((ru) =>
        ["Ski", "Snowboard", "XcSki", "Snowshoe"].includes(ru.inventory_item.type),
      );
      const bootRentalUnit = rentalUnits.find((ru) =>
        ["SkiBoot", "SnowboardBoot", "XcBoot"].includes(ru.inventory_item.type),
      );
      const helmetRentalUnit = rentalUnits.find((ru) => ["Helmet"].includes(ru.inventory_item.type));
      const bikeRentalUnit = rentalUnits.find((ru) => ["Bike"].includes(ru.inventory_item.type));
      const tubeRentalUnit = rentalUnits.find((ru) => ["Tube"].includes(ru.inventory_item.type));
      const paddleboardRentalUnit = rentalUnits.find((ru) => ["Paddleboard"].includes(ru.inventory_item.type));

      let url: string,
        params:
          | { bike_assignment: BikeAssignment }
          | { tube_assignment: TubeAssignment }
          | { paddleboard_assignment: PaddleboardAssignment }
          | { equipment_assignment: EquipmentAssignment };

      if (bikeRentalUnit) {
        const bikeAssignment: BikeAssignment = { rental_id: rental.id, bike_rental_unit_id: bikeRentalUnit.id };
        if (helmetRentalUnit) bikeAssignment.helmet_rental_unit_id = helmetRentalUnit.id;
        url = `/api/v1/rentals/${rental.id}/bike_assignment`;
        params = { bike_assignment: bikeAssignment };
      } else if (tubeRentalUnit) {
        const tubeAssignment: TubeAssignment = { rental_id: rental.id, tube_rental_unit_id: tubeRentalUnit.id };
        url = `/api/v1/rentals/${rental.id}/tube_assignment`;
        params = { tube_assignment: tubeAssignment };
      } else if (paddleboardRentalUnit) {
        const paddleboardAssignment: PaddleboardAssignment = {
          rental_id: rental.id,
          paddleboard_rental_unit_id: paddleboardRentalUnit.id,
        };
        url = `/api/v1/rentals/${rental.id}/paddleboard_assignment`;
        params = { paddleboard_assignment: paddleboardAssignment };
      } else {
        const equipmentAssignment: EquipmentAssignment = { rental_id: rental.id };
        if (skiRentalUnit) equipmentAssignment.ski_rental_unit_id = skiRentalUnit.id;
        if (bootRentalUnit) {
          equipmentAssignment.boot_rental_unit_id = bootRentalUnit.id;
          equipmentAssignment.has_own_boots = false;
        }
        if (helmetRentalUnit) equipmentAssignment.helmet_rental_unit_id = helmetRentalUnit.id;
        url = `/api/v1/rentals/${rental.id}/equipment_assignment`;
        params = { equipment_assignment: equipmentAssignment };
      }

      console.log("submitting...");
      const response = await axios.put(url, params);
      if (response.status === 202) {
        submitted = true;
        errors = [];
        window.location.pathname = `/rentals/${rental.id}`;
        console.log("equipment assignment submitted!");
      } else {
        console.error(response);
      }
    } catch (err: unknown | AxiosError) {
      if (axios.isAxiosError(err)) {
        if (err.isAxiosError && err.response && err.response.status === 422) {
          errors = err.response.data.errors;
        }
      } else {
        if (err instanceof Error) {
          errors = [err.message];
        } else {
          errors = ["There was an error submitting the equipment assignment. Please try again"];
        }
      }
      console.error(err);
    }
    submitting = false;
    return;
  };
</script>

<div>
  {#if env !== "test"}
    <div class="mt-4 d-flex justify-content-center">
      <BarcodeScanner {onDetected} {facingMode} />
    </div>
  {/if}

  <div class="mt-4 d-flex align-items-top flex-wrap justify-content-between">
    <p class="mt-2 mb-0 me-2 text-secondary font-italic">Or manually search:</p>
    <div class="flex-grow-1">
      <RentalUnitSelect
        onSelected={onSelectedViaAutosuggest}
        {areaId}
        {itemTypes}
        inputId="rental_unit"
        inputName="rental_unit"
        clearAfterSelect={true}
      >
        <div slot="selection">Add another item by name, SKU, or barcode...</div>
      </RentalUnitSelect>
    </div>
  </div>

  {#if fetching}
    <div class="mt-4 text-center">
      <i class="fas fa-spinner fa-spin fa-2x" />
    </div>
  {/if}

  <div class="mt-4">
    {#if rentalUnits.length > 0}
      {#each rentalUnits as rentalUnit (rentalUnit.id)}
        <RentalUnitCard {rentalUnit} onRemove={removeRentalUnit} />
      {/each}
    {:else if unknownBarcodes.length > 0}
      {#each unknownBarcodes as unknownBarcode (unknownBarcode)}
        <div class="alert alert-warning d-flex align-items-center justify-content-between">
          <p class="mb-0 fs-6">
            Unrecognized barcode detected: <span class="fw-bold fs-5">{unknownBarcode}</span>
          </p>
          <a
            href={`/barcode_assignments/new?barcode=${unknownBarcode}&area_id=${areaId}&rental_id=${rental.id}`}
            class="btn btn-warning"
          >
            Assign Barcode<i class="fas fa-chevron-right ms-2" />
          </a>
        </div>
      {/each}
    {:else}
      <p class="text-primary text-center font-italic">
        Please scan one or more equipment <strong>barcodes</strong> to continue.
      </p>
    {/if}
  </div>

  <div class="mt-4">
    {#if errors.length > 0}
      {#each errors as error}
        <p class="alert alert-danger text-center">
          {error}
        </p>
      {/each}
    {/if}

    {#if validation.isValid}
      {#if submitted}
        <p class="text-success text-center">
          <i class="fas fa-spinner fa-spin me-2" />Equipment assigned successfully, returning to rental...
        </p>
      {:else}
        <div class="d-flex flex-wrap justify-content-center align-items-center">
          <button type="button" on:click={onSubmit} disabled={submitting} class="btn btn-success btn-lg">
            <i class="fas fa-check me-2" />
            <span>Assign Equipment</span>
          </button>
        </div>
      {/if}
    {:else if rentalUnits.length > 0}
      <div class="alert alert-danger text-center">
        Please select only <strong>one</strong> item of each type for this renter&rsquo;s selected package.
      </div>
    {/if}
  </div>
</div>
