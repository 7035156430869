$(document).on("turbo:load", function () {
  $("#select-all").click(function (e) {
    e.preventDefault();
    selectAllRentalUnits();
  });

  $("#unselect-all").click(function (e) {
    e.preventDefault();
    unselectAllRentalUnits();
  });

  function selectAllRentalUnits() {
    $(".rental-unit-checkbox").prop("checked", true);
  }

  function unselectAllRentalUnits() {
    $(".rental-unit-checkbox").prop("checked", false);
  }
});
