import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bootCodeInputWrapper", "hasOwnBootsCheckbox", "bootSoleLengthInput"];

  connect() {
    setTimeout(() => {
      this.toggleInputs();
    });
  }

  toggleInputs() {
    if (this.hasOwnBootsCheckboxTarget.checked) {
      this.showBootSoleLengthInput();
      this.hideBootCodeInput();
    } else {
      this.showBootCodeInput();
      this.hideBootSoleLengthInput();
    }
  }

  showBootCodeInput() {
    this.bootCodeInputWrapperTarget.classList.remove("d-none");
  }

  hideBootCodeInput() {
    this.bootCodeInputWrapperTarget.classList.add("d-none");
  }

  showBootSoleLengthInput() {
    this.bootSoleLengthInputTarget.classList.remove("d-none");
  }

  hideBootSoleLengthInput() {
    this.bootSoleLengthInputTarget.classList.add("d-none");
  }
}
