<script lang="ts" context="module">
  import type { QuaggaJSResultObject_CodeResult } from "@ericblade/quagga2";

  function getMedian(arr: number[]): number {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
      return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
  }

  function isNumber(x: number | undefined): x is number {
    return x !== undefined;
  }

  function getMedianOfCodeErrors(decodedCodes: QuaggaJSResultObject_CodeResult["decodedCodes"]) {
    const errors = decodedCodes.map((x) => x.error).filter(isNumber);
    const medianOfErrors = getMedian(errors);
    return medianOfErrors;
  }
</script>

<script lang="ts">
  import Quagga, {
    type QuaggaJSConfigObject,
    type QuaggaJSResultObject,
    type QuaggaJSResultCallbackFunction,
  } from "@ericblade/quagga2";
  import { onDestroy, onMount } from "svelte";

  export let onDetected: (code: string) => Promise<void>;
  export let facingMode: MediaTrackConstraints["facingMode"];

  let status: "waiting" | "loading" | "scanning" | "error" = "waiting";
  let error: string | null = null;
  let detectedCodes: string[] = [];
  let quaggeEl: HTMLElement;

  let quaggaConfig: QuaggaJSConfigObject = {
    inputStream: {
      type: "LiveStream",
      constraints: {
        facingMode: facingMode || "environment",
      },
      target: "#scanner",
    },
    locator: {
      patchSize: "medium",
      halfSample: true,
    },
    numOfWorkers: 2,
    frequency: 10,
    decoder: {
      // all current equipment stickers are going to be Code 128-C, but might want to eventually scan retail codes?
      readers: ["code_128_reader", "ean_reader", "upc_reader"],
    },
    locate: true,
  };

  const handleError = (err: unknown) => {
    console.error(err);
    status = "error";
    if (err instanceof Error) {
      error = err.message;
    }
  };

  const errorCheck: QuaggaJSResultCallbackFunction = (result) => {
    if (!onDetected) return;
    console.log("checking error");

    // if Quagga is at least 50% certain that it read correctly, accept the code.
    const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
    if (err < 0.5) {
      const code = result.codeResult.code;
      if (code === null) return;

      if (!detectedCodes.includes(code)) {
        detectedCodes = [...detectedCodes, code];
        onDetected(code);
        console.log(code, "detected");
      } else {
        console.log(code, "already found");
      }
    }
  };

  const onProcessed: QuaggaJSResultCallbackFunction = (result) => {
    if (!result) return;
    console.log("processed with result:", JSON.stringify(result, null, 2));

    try {
      const drawingCtx = Quagga.canvas.ctx.overlay;
      const drawingCanvas = Quagga.canvas.dom.overlay;

      if (result.boxes) {
        drawingCtx.clearRect(
          0,
          0,
          parseInt(drawingCanvas.getAttribute("width") ?? "0"),
          parseInt(drawingCanvas.getAttribute("height") ?? "0"),
        );
        result.boxes
          .filter((box) => box !== result.box)
          .forEach((box) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
          });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(result.line, { x: "x", y: "y" }, drawingCtx, { color: "red", lineWidth: 3 });
      }
    } catch (err) {
      handleError(err);
    }
  };

  const loadQuagga = () => {
    console.log("loading Quagga");
    try {
      status = "loading";
      Quagga.init(quaggaConfig, (err) => {
        if (err) return handleError(err);
        Quagga.start();
        status = "scanning";
      });
      Quagga.onProcessed(onProcessed);
      Quagga.onDetected(errorCheck);
    } catch (err) {
      handleError(err);
    }
  };

  $: if (detectedCodes || error) {
    console.log("refreshing onDetected(errorCheck)");
    Quagga.offDetected();
    Quagga.onDetected(errorCheck);
  }

  onMount(() => {
    if (status === "waiting") {
      loadQuagga();
    }
  });

  onDestroy(() => {
    console.log("cleaning up Quagga");
    Quagga.stop();
    Quagga.offDetected();
    Quagga.offProcessed();
  });
</script>

<div id="scanner" bind:this={quaggeEl} />
