<script lang="ts" context="module">
  type Item = { [key: string]: any };
</script>

<script lang="ts">
  import Select from "svelte-select";
  import axios from "axios";

  export let inputId: string;
  export let inputName: string;
  export let inputLabel: string | undefined = undefined;
  export let inputPlaceholder = "Start typing to search...";

  export let searchUrl: string;
  export let searchParamKey: string = "q";
  export let searchResultsNamespace: string;
  export let itemIdProperty: string = "id";
  export let itemLabelProperty: string = "name";
  export let minimumFilterLength: number = 3;

  export let selected: Item | null = null;
  export let value: number | null = null;
  export let onSelected: (selected: Item) => void = () => {};

  let filterText: string;

  $: value = selected ? selected[itemIdProperty] : null;
  $: selectionText = selected ? selected[itemLabelProperty] : "";

  async function fetchResults(filterText: string): Promise<Item[]> {
    let results: Item[] = [];

    if (filterText.length < 3) {
      return results;
    }

    try {
      const params = { [searchParamKey]: filterText };
      const response = await axios.get(searchUrl, { params });

      if (response.status == 200) {
        results = response.data[searchResultsNamespace] as Item[];
      }
    } catch (err) {
      console.error(err);
    }

    return results;
  }

  const onChange = (event: { detail: Item }) => {
    const newSelection = event.detail;
    onSelected(newSelection);
  };

  const onClear = () => {
    selected = null;
  };
</script>

<div class="form-group">
  {#if inputLabel}
    <label for={inputId}>{inputLabel}</label>
  {:else}
    <label for={inputId} class="sr-only">{inputPlaceholder}</label>
  {/if}

  <input type="hidden" name={inputName} bind:value />

  <Select
    loadOptions={fetchResults}
    placeholder={inputPlaceholder}
    inputAttributes={{ id: inputId, autocomplete: "off" }}
    --height="32px"
    --item-height="48px"
    on:change={onChange}
    on:clear={onClear}
    bind:value={selected}
    bind:filterText
  >
    <slot name="item" slot="item" let:item {item}>
      <div class="pt-2">
        {item[itemLabelProperty]}
      </div>
    </slot>
    <slot name="selection" slot="selection">{selectionText}</slot>

    <div slot="empty" class="d-flex pt-2 align-items-center justify-content-center">
      {#if filterText?.length >= minimumFilterLength}
        <p class="text-secondary">No results, please try another search</p>
      {:else}
        <p class="text-info fst-italic">You must type atleast {minimumFilterLength} characters to start searching</p>
      {/if}
    </div>
  </Select>
</div>
