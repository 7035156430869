<script lang="ts" context="module">
  import type { Location } from "models/Location";

  interface Option {
    value: number | string;
    label: string;
    new?: boolean;
  }

  function labelFor(location: Location): string {
    return [location.name, location.street].filter((s) => s && s.length > 0).join(" - ");
  }

  function optionFor(location: Location): Option {
    return { value: location.id, label: labelFor(location) };
  }
</script>

<script lang="ts">
  import Select from "svelte-select";
  import axios from "axios";

  export let inputId: string;
  export let inputName: string;
  export let label: string;
  export let placeholder = "Select or add a location...";
  export let value: number | string | null;
  export let selected: Location | undefined = undefined;
  export let branchId: number;
  export let disabled: boolean = false;

  export let selectedOption: Option | undefined = selected ? optionFor(selected) : undefined;
  let filterText: string;
  let error: string | undefined = undefined;

  $: value = selectedOption ? selectedOption.value : null;
  $: hasError = !!(error && error.length > 0);

  async function fetchLocations(filterText: string): Promise<Option[]> {
    let results: Option[] = [];

    if (filterText.length < 3) {
      return results;
    }

    try {
      const url = `/api/v1/branches/${branchId}/locations?q=${filterText}`;
      const response = await axios.get(url);

      if (response.status == 200) {
        results = (response.data.locations as Location[]).map(optionFor);
      }
    } catch (err) {
      console.error(err);
    }

    if (filterText.length > 0) {
      results.push({ value: "NEW", label: filterText, new: true });
    }

    return results;
  }

  const handleSelect = async (event: CustomEvent<{ detail: Option }>) => {
    if (selectedOption?.new) {
      const createdLocation = await createLocation(selectedOption.label);
      if (createdLocation) {
        selected = createdLocation;
        selectedOption = optionFor(createdLocation);
        value = createdLocation.id;
      } else {
        error = "Failed to add location, please try again";
      }
    }
  };

  const createLocation = async (name: string): Promise<Location | null> => {
    let result: Location | null = null;

    try {
      const response = await axios.post("/api/v1/locations", { location: { name, branch_ids: [branchId] } });
      const location = response.data.location as Location;
      result = location;
    } catch (err) {
      console.error(err);
    }

    return result;
  };
</script>

<div class="form-group mb-1">
  <label for={inputId}>{label}</label>

  <Select
    loadOptions={fetchLocations}
    bind:value={selectedOption}
    bind:filterText
    on:change={handleSelect}
    {placeholder}
    inputAttributes={{ id: inputId, autocomplete: "off" }}
    {disabled}
    {hasError}
    --height="32px"
  >
    <div slot="item" let:item>
      {#if item.new}
        <span class="text-muted">{item.new ? "Add location: " : ""}</span>
        <span class="text-primary">{item.label}</span>
      {:else}
        <span>{item.label}</span>
      {/if}
    </div>

    <div slot="empty" class="d-flex pt-2 align-items-center justify-content-center">
      {#if filterText?.length >= 3}
        <p class="text-secondary">No results, please try another search</p>
      {:else}
        <p class="text-info fst-italic">You must type atleast 3 characters to start searching</p>
      {/if}
    </div>
  </Select>

  <input type="hidden" name={inputName} {value} />

  {#if error}
    <p class="text-danger fst-italic">{error}</p>
  {/if}
</div>
