<script lang="ts" context="module">
  import type { ReservationGroup } from "models/ReservationGroup";
</script>

<script lang="ts">
  import Select from "svelte-select";
  import axios from "axios";

  export let selectedGroup: ReservationGroup | null = null;

  let groupId: number | null = selectedGroup?.id ?? null;
  let groupName: string | null = selectedGroup?.name ?? null;
  let groupNotes: string | null = selectedGroup?.notes ?? null;

  let filterText: string;
  let lastInputText: string = "";
  $: if (filterText?.length > 0) {
    lastInputText = filterText;
  }

  async function fetchResults(filterText: string): Promise<ReservationGroup[]> {
    let results: ReservationGroup[] = [];

    if (filterText.length < 3) {
      return results;
    }

    try {
      const url = "/api/v1/reservation_groups";
      const params = { q: filterText };
      const response = await axios.get(url, { params });

      if (response.status == 200) {
        results = response.data.reservation_groups as ReservationGroup[];
      }
    } catch (err) {
      console.error(err);
    }

    return results;
  }

  const onChange = (event: { detail: ReservationGroup }) => {
    const newSelection = event.detail;
    groupId = newSelection.id;
    groupName = newSelection.name;
    groupNotes = newSelection.notes;
  };

  const onBlur = () => {
    console.log("on:blur fired");
    if (!selectedGroup && lastInputText.length > 0) {
      selectedGroup = {
        id: null,
        name: lastInputText,
        notes: null,
      };
      groupId = null;
      groupName = lastInputText;
      groupNotes = null;
    }
  };

  const onClear = () => {
    lastInputText = "";
    groupId = null;
    groupName = null;
    groupNotes = null;
  };
</script>

<div class="reservation-group-fields">
  <input type="hidden" id="reservation_reservation_group_id" name="reservation[reservation_group_id]" value={groupId} />
  <input type="hidden" id="reservation_group_attributes_id" name="reservation[group_attributes][id]" value={groupId} />
  <input
    type="hidden"
    id="reservation_group_attributes_name"
    name="reservation[group_attributes][name]"
    value={groupName}
  />

  <div class="form-group">
    <label for="reservation_group_name">Group Name</label>
    <Select
      loadOptions={fetchResults}
      placeholder=""
      inputAttributes={{ id: "reservation_group_name", autocomplete: "off" }}
      --height="32px"
      --item-height="48px"
      on:change={onChange}
      on:blur={onBlur}
      on:clear={onClear}
      bind:value={selectedGroup}
      bind:filterText
    >
      <div slot="item" class="pt-2" let:item>
        {item.name}
      </div>

      <div slot="selection">{selectedGroup ? selectedGroup.name : groupName}</div>

      <div slot="empty" />
    </Select>
  </div>

  <div class="mt-2 form-group">
    <label for="reservation_group_notes">Group Notes</label>
    <textarea
      id="reservation_group_notes"
      name="reservation[group_attributes][notes]"
      class="form-control form-control-sm"
      bind:value={groupNotes}
    />
  </div>
</div>
