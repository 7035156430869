import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "inventoryItemRadio", "rentalUnitRadio" ]

  deselectRentalUnitRadios(event) {
    console.log('inventoryItemRadio change event fired')

    this.rentalUnitRadioTargets.forEach((rentalUnitRadio) => {
      rentalUnitRadio.checked = false
    })
  }
}
