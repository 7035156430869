<script lang="ts" context="module">
  import type { Referrer } from "models/Referrer";
</script>

<script lang="ts">
  import RemoteSelect from "./RemoteSelect.svelte";

  export let inputId: string;
  export let inputName: string;
  export let inputLabel: string | undefined = undefined;
  export let inputPlaceholder = "Find by name or slug...";

  export let value: number | null = null;
  export let selected: Referrer | null = null;
  export let onSelected: (selected: Referrer) => void = () => {};
</script>

<RemoteSelect
  {inputId}
  {inputName}
  {inputLabel}
  {inputPlaceholder}
  bind:value
  bind:selected
  {onSelected}
  searchUrl="/api/v1/referrers"
  searchParamKey="q"
  searchResultsNamespace="referrers"
  itemIdProperty="id"
  itemLabelProperty="name"
/>
