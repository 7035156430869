<script lang="ts" context="module">
  type RentalContractSignature = {
    id: number;
    rental_id: number;
    appointment_id: number | null;
    hellosign_signature_request_id: string;
    hellosign_signature_id: string;
    hellosign_sign_url: string;
    completed: boolean;
    active: boolean;
    created_at: string; // ISO time string
    updated_at: string; // ISO time string
  };
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import HelloSign from "hellosign-embedded";
  import { Turbo } from "@hotwired/turbo-rails";

  export let signature: RentalContractSignature;
  export let clientId: string;
  export let testMode: boolean;
  export let error: string | undefined = undefined;

  let client: HelloSign;

  onMount(() => {
    try {
      client = new HelloSign({ clientId });

      client.on(HelloSign.events.SIGN, () => {
        console.log("Signature completed successfully");
        Turbo.visit(`/signatures/${signature.id}/completion`);
      });

      client.on(HelloSign.events.CANCEL, () => {
        console.log("Signature request cancelled");
        Turbo.visit(`/rentals/${signature.rental_id}`);
      });

      client.on(HelloSign.events.ERROR, (data) => {
        console.log(`Something went wrong! Please try again. Error code: ${data.code}`);
        client.close();
      });

      client.open(signature.hellosign_sign_url, {
        debug: true,
        skipDomainVerification: testMode,
      });
    } catch (err) {
      if (err instanceof Error) {
        error = err.message;
      } else {
        error = "Something went wrong. Please try again.";
      }
      console.error(err);
    }
  });
</script>
