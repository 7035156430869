console.log("Vite ⚡️ Rails");
console.log("Visit the guide for more information: ", "https://vite-ruby.netlify.app/guide/rails");

// Import jQuery and add it to global namespace
import jQuery from "jquery";
Object.assign(window, { $: jQuery, jQuery });

// import * as Rails from "@rails/ujs";
// Rails.start();

import "@hotwired/turbo-rails";

// Stylesheet Dependencies
import "bootstrap/dist/css/bootstrap.css";
import "@fullcalendar/common/main.css";
import "@fullcalendar/resource-timeline/main.css";
import "flatpickr/dist/flatpickr.css";

// Application Stylesheets
import "~/shared/global.css";
import "~/shared/animate.css";
import "~/application/layout.css";
import "~/application/flatpickr.css";
import "~/application/autosuggest.css";
import "~/application/scanner.css";

// Javascript Dependencies
import "bootstrap";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fullcalendar/core/vdom"; // required to not get import error when importing in svelte, see https://stackoverflow.com/a/74780401
import "flatpickr/dist/flatpickr";
import "cocoon-js";

// Custom Javascript
import "~/application/fontawesome";
import "~/application/turbolinks-animation";
import "~/application/date_picker";
import "~/application/reservation_form";
import "~/application/transaction_form";
import "~/application/inventory_addition_form";
import "~/application/equipment_label_print_form";
import "~/application/vehicle_usage_form";
import "~/application/appointment_form";
import "~/application/employee_pin_login";

// StimulusJS
import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";
const application = Application.start();
const controllers = import.meta.globEager("~/controllers/*_controller.js");
registerControllers(application, controllers);

// Svelte Components in Rails views
import { register } from "~/application/svelte_components";
const svelteComponents = import.meta.globEager("~/components/**/*.svelte");
const componentsMap = {};
Object.entries(svelteComponents).forEach(([path, component]) => {
  const name = path.split("/").pop().split(".").shift();
  componentsMap[name] = component.default;
});
document.addEventListener("turbo:load", () => register(componentsMap));
