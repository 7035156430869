<script lang="ts" context="module">
  import type { RentalUnit } from "models/RentalUnit";
  import type { InventoryItemType } from "models/InventoryItem";

  type RentalUnitSearchParams = {
    q: string;
    area_id: number;
    types?: InventoryItemType[];
  };

  function labelFor(rentalUnit: RentalUnit): string {
    return [rentalUnit.barcode_sku, rentalUnit.rental_sku, rentalUnit.inventory_item.name]
      .filter((v) => v && v.length > 0)
      .join(" ");
  }
</script>

<script lang="ts">
  import Select from "svelte-select";
  import axios from "axios";

  export let inputId: string;
  export let inputName: string;
  export let value: number | null = null;
  export let label: string | undefined = undefined;
  export let placeholder = "Find by name, SKU, or barcode...";

  export let areaId: number;
  export let itemTypes: InventoryItemType[] | undefined = undefined;
  export let selected: RentalUnit | null = null;
  export let onSelected: (selected: RentalUnit) => void = () => {};
  export let clearAfterSelect = false;

  let filterText: string;
  const inputAttributes = { id: inputId, autocomplete: "off" };

  async function fetchRentalUnits(filterText: string): Promise<RentalUnit[]> {
    let results: RentalUnit[] = [];

    if (filterText.length < 3) {
      return results;
    }

    try {
      const url = "/api/v1/rental_units";
      const params: RentalUnitSearchParams = { q: filterText, area_id: areaId };
      if (itemTypes) params.types = itemTypes;

      const response = await axios.get(url, { params });

      if (response.status == 200) {
        results = response.data.rental_units;
      }
    } catch (err) {
      console.error(err);
    }

    return results;
  }

  const onChange = (event: { detail: RentalUnit }) => {
    const rentalUnit = event.detail;
    value = rentalUnit.id;
    onSelected(rentalUnit);
    if (clearAfterSelect) {
      selected = null;
      value = null;
    }
  };

  const onClear = () => {
    selected = null;
    value = null;
  };
</script>

<div class="form-group">
  {#if label}
    <label for={inputId}>{label}</label>
  {:else}
    <label for={inputId} class="sr-only">{placeholder}</label>
  {/if}

  <input type="hidden" name={inputName} bind:value />

  <Select
    loadOptions={fetchRentalUnits}
    {placeholder}
    {inputAttributes}
    --height="32px"
    --item-height="48px"
    on:change={onChange}
    on:clear={onClear}
    bind:value={selected}
    bind:filterText
  >
    <div slot="item" let:item>
      <div class="pt-2 d-flex align-items-center">
        {#if item.barcode_sku && item.barcode_sku.length > 0}
          <div class="text-secondary pe-4">
            <i class="fas fa-barcode" />
            {item.barcode_sku}
          </div>
        {/if}

        {#if item.rental_sku && item.rental_sku.length > 0}
          <div class="text-secondary pe-4">
            <i class="fas fa-tag" />
            {item.rental_sku}
          </div>
        {/if}

        <div>{item.inventory_item.name}</div>
      </div>
    </div>

    <div slot="selection"><slot name="selection">{selected ? labelFor(selected) : ""}</slot></div>

    <div slot="empty" class="d-flex pt-2 align-items-center justify-content-center">
      {#if filterText?.length >= 3}
        <p class="text-secondary">No results, please try another search</p>
      {:else}
        <p class="text-info fst-italic">You must type atleast 3 characters to start searching</p>
      {/if}
    </div>
  </Select>
</div>
