export type ComponentsMap = { [key: string]: any };

export function register(components: ComponentsMap) {
  for (const key in components) {
    const targets = document.querySelectorAll(`[data-svelte-component='${key}']`);

    if (targets.length === 0) continue;

    targets.forEach((target) => {
      const rawProps = target.getAttribute("data-svelte-props");
      const props = rawProps ? JSON.parse(rawProps) : {};

      const comp = components[key];
      const inst = new comp({
        target,
        props,
      });
    });
  }
}
