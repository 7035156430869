import { Controller } from "stimulus";
import { Collapse } from "bootstrap";

export default class extends Controller {
  static targets = ["inventoryItemRadio", "rentalUnitRadio", "unitsToggleLink", "rentalUnitsContainer"];

  unitsShowing = false;

  connect() {
    this.rentalUnitsCollapse = new Collapse(this.rentalUnitsContainerTarget, { toggle: false });
  }

  selectParentInventoryItem(event) {
    console.log("rentalUnitRadio change event fired");
    this.inventoryItemRadioTarget.checked = true;
  }

  toggleUnits(event) {
    console.log("toggling units for ", event.currentTarget.hash);
    console.log(this.unitsToggleLinkText);
    console.log(this.unitsToggleLinkIcon);
    event.preventDefault();
    if (this.unitsShowing) {
      this.hideUnits();
    } else {
      this.showUnits();
    }
  }

  showUnits() {
    this.unitsShowing = true;
    this.rentalUnitsCollapse.show();
    this.unitsToggleLinkText.textContent = "Hide Units";
    this.unitsToggleLinkIcon.classList.remove("fa-caret-down");
    this.unitsToggleLinkIcon.classList.add("fa-caret-up");
  }

  hideUnits() {
    this.unitsShowing = false;
    this.deselectAllRentalUnits();
    this.rentalUnitsCollapse.hide();
    this.unitsToggleLinkText.textContent = "Show Units";
    this.unitsToggleLinkIcon.classList.remove("fa-caret-up");
    this.unitsToggleLinkIcon.classList.add("fa-caret-down");
  }

  deselectAllRentalUnits() {
    this.rentalUnitRadioTargets.forEach((rentalUnitRadio) => {
      rentalUnitRadio.checked = false;
    });
  }

  get unitsToggleLinkText() {
    return this.unitsToggleLinkTarget.querySelector("span");
  }

  get unitsToggleLinkIcon() {
    return this.unitsToggleLinkTarget.querySelector("svg");
  }
}
