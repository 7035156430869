$(document).on("turbo:load", function () {
  setGroupInputHandlers();
  setupDatePickers();
  generateIdsForCustomers();

  $("#reservation-customers").on("cocoon:after-insert", function (e, insertedItem) {
    FontAwesome.dom.i2svg();
    setupDatePickersForNewCustomer();
    generateIdForNewCustomer();
    copyFieldsFromPrimaryCustomer();
    focusNewCustomer();
  });

  function setGroupInputHandlers() {
    $("#clear_reservation_group").click(handleClearGroup);
  }

  function handleClearGroup(e) {
    e.preventDefault();

    // we clear the foreign key, which will trigger a custom handler on backend...
    // the custom handler expects that if you want to clear the group,
    // you will pass a blank foreign key but leave the nested group ID present
    $("#reservation_reservation_group_id").val("");

    // we'll clear the visible inputs, though, just to not confuse the user
    $("#reservation_group_attributes_name").val("");
    $("#reservation_group_attributes_notes").val("");
  }

  function setupDatePickers() {
    $(".date_picker").flatpickr({
      disableMobile: true,
    });
    $(".date_time_picker").flatpickr({
      enableTime: true,
      disableMobile: true,
    });
  }

  function generateIdsForCustomers() {
    const $newReservationCustomers = $(".new.reservation-customer");
    $newReservationCustomers.each(function (index) {
      $(this).attr("id", `new_customer_${index}`);
    });
  }

  function setupDatePickersForNewCustomer() {
    const $unmountedDatePickerInputs = $(".date_picker").filter(":input").not(".flatpickr-input");
    $unmountedDatePickerInputs.each(function (i, newInput) {
      this.flatpickr({});
    });
  }

  function generateIdForNewCustomer() {
    const $newReservationCustomers = $(".new.reservation-customer");
    const $newReservationCustomer = $newReservationCustomers.last();
    const newIndex = $newReservationCustomers.length - 1;
    $newReservationCustomer.attr("id", `new_customer_${newIndex}`);
  }

  function copyFieldsFromPrimaryCustomer() {
    const $primaryCustomer = $(".reservation-customer");
    if ($primaryCustomer.length) {
      const $newReservationCustomer = $(".new.reservation-customer").last();

      if (!$newReservationCustomer.find("input.last_name").val().length) {
        const primaryLastName = $primaryCustomer.find("input.last_name").val();
        $newReservationCustomer.find("input.last_name").val(primaryLastName);
      }

      if (!$newReservationCustomer.find("input.requested_delivery_date").val().length) {
        const primaryRequestedDeliveryDate = $primaryCustomer.find("input.requested_delivery_date").val();
        $newReservationCustomer.find("input.requested_delivery_date").val(primaryRequestedDeliveryDate);
      }

      if (!$newReservationCustomer.find("input.end_date").val().length) {
        const primaryEndDate = $primaryCustomer.find("input.end_date").val();
        $newReservationCustomer.find("input.end_date").val(primaryEndDate);
      }
    }
  }

  function focusNewCustomer() {
    const $newReservationCustomer = $(".new.reservation-customer").last();
    $newReservationCustomer.find("input.first_name").focus();
  }
});
