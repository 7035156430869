import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mobileMenu"];

  collapseShown = false;

  toggle() {
    this.mobileMenuTarget.classList.toggle("show");
  }
}
